<template>
  <div class="divider">
    <svg xmlns="http://www.w3.org/2000/svg" fill="rgb(213 225 255)" viewBox="0 0 1185 248">
      <circle cx="76" cy="121.1" r="20" class="a" />
      <circle cx="870" cy="201.1" r="11" class="a" />
      <circle cx="814.5" cy="165.6" r="24.5" class="a" />
      <path
        d="M0 0v17.7c22.7 14.8 53 31.9 90.7 51.5 150.8 78 322 116.6 424.8 69.3 102.9-47.4 138-69.3 210.8-69.3s118.3 48.6 219.5 38.3 76.3-59.3 188.7-59.3c18.9 0 35.5 2.6 50.5 6.8V0H0z"
        class="a"
      />
    </svg>
    <h1>Les cours particuliers</h1>
  </div>
</template>
<script>
export default {
  name: "Divider",
  props: {},
};
</script>

<style>
.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    text-transform: uppercase;
    padding: 50px;
  }
}
</style>
