<template>
  <div class="banner" id="home-banner">
    <div id="text-banner">
      <v-img :src="require('@/assets/logo-tsukigo.png')"
            width="400px"
            alt="Tsukigo"
          />
      <!-- <div id="contact-info">
        <h3 class="important" id="white-color">tsukigo.japonais@gmail.com</h3>
        <h3 class="important" id="white-color">06 73 11 71 59</h3>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeBanner",
  props: {},
};
</script>

<style>
.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
}

#home-banner {
  background: url("../assets/background.jpg") no-repeat center;
  background-size: cover;
  height: 600px;
  font-size: xx-large;
  overflow: hidden;
}

#text-banner {
  padding-top: 80px;
}
#contact-info {
  background: rgb(0, 0, 0, 0.2);
  padding: 5px;
}
#white-color {
  color: whitesmoke;
}

.middle-text {
  max-width: 800px;
  min-width: 100px;
  margin: 10px;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}
</style>
