<template>
  <div class="questionnaire" id="formation">
    <h1>Questionnaire</h1>
    <div class="middle-text">
      <p class="center">
        Vous êtes intéressé(e) par la langue japonaise ? Vous souhaitez
        commencer, continuer ou reprendre votre apprentissage ? Aidez-moi à
        développer une plateforme en ligne pour rendre le japonais accessible à
        tous ! Pour cela, remplissez ce questionnaire et recevez en remerciement
        des fiches de cours gratuites
      </p>
      <v-btn
        elevation="4"
        href="https://docs.google.com/forms/d/e/1FAIpQLSfYQTSr_yPwcvnRSV0HRDWdWA_L2u_2-WcL_3KLFq6NKr5TrQ/viewform"
        target="_blank"
        color="#6071d7"
        medium
        id="download-btn"
        ><a>Le formulaire</a></v-btn
      >
      <v-img
        src="../assets/illus-fiche-cours-gratuite-2.png"
        max-height="300"
        width="auto"
        :contain="true"
        class="questionnaire-img"
      />
      <div class="questionnaire-info">
        <h3>Le projet Tsukigo, qu'est ce que c'est ?</h3>
        La formation offrira les ressources suivantes:
        <ul>
          <li>des cours détaillés en vidéo</li>
          <li>des fiches pdf de leçons, vocabulaire et exercices</li>
          <li>de nombreux exercices interactifs et quiz en ligne</li>
          <li>des supports de compréhension et d'expression écrite et orale</li>
        </ul>
        <p class="center">
          Le tout sur une plateforme qui vous guide et vous permet de suivre
          votre progression. Les modules seront achetables séparément pour
          s'adapter à vos besoins et votre budget.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Questionnaire",
  props: {},
};
</script>

<style scoped>
.questionnaire {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(213 225 255);
  padding-top: 30px;

  button {
    background-color: rgb(96, 113, 215);
    color: white;
  }
  .middle-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #download-btn {
    margin-block: 20px;
  }
}
#second-banner {
  padding-block: 30px;
  z-index: 1;
}
p {
  padding-block: 10px;
}
ul {
  width: fit-content;
  text-align: left;
}
.questionnaire-info {
  text-align: left;
  display: flex;
  justify-self: center;
  flex-direction: column;

  h3 {
    padding-block: 20px 10px;
    text-align: center;
  }
}
.center {
  text-align: center;
}
.questionnaire-img {
  margin-block: 20px 20px;
}
</style>
