<template>
  <div id="infos-pratiques">
    <v-row class="banner" justify="center">
      <h1 class="info-title">Infos pratiques</h1>

      <v-row
        class="middle-text"
        justify="center"
        align="center"
        no-gutters
      >
        <v-col cols="12" sm="6" md="3" class="text-center">
          <v-icon large color="#6071d7"> mdi-clock-outline </v-icon>
          <p>Du mardi au samedi<br />Cours de<br />1h ou 1h30</p>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="text-center">
          <v-icon large color="#6071d7"> mdi-wifi </v-icon>
          <p>En ligne sur<br />Skype, Discord ou Google Meets</p>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="3" class="text-center">
          <v-icon large color="#6071d7"> mdi-map-marker-radius </v-icon>
          <p>En présentiel</p>
        </v-col> -->
        <v-col cols="12" sm="6" md="3" class="text-center">
          <v-icon large color="#6071d7"> mdi-account-group </v-icon>
          <p>De grand débutant à intermédiaire<br />À partir de 10 ans</p>
        </v-col>
      </v-row>
    </v-row>

    <div class="d-flex justify-center">
      <img src="../assets/learn.jpg" id="img" />
    </div>

    <v-row id="infos-banner" justify-content="space-around">
      <h3 id="infos-title">TARIFS</h3>
      <v-icon>mdi-star-outline </v-icon>
      <div class="d-flex justify-center">
        <span class="emphasize center">Première heure d'essai gratuite !</span>
      </div>
      <v-col id="text-infos" align-self="center">
        <div id="tarifs">
          <h4>Cours d'une personne</h4>
          <div id="tarifs-text">
            <span>Une heure: <em>23€</em></span>
            <span>Une heure et demie: <em>30€</em></span>
          </div>
        </div></v-col
      >
      <v-col id="text-infos" align-self="center">
        <div id="tarifs">
          <h4>Cours de deux personnes</h4>
          <div id="tarifs-text">
            <span>Une heure: <em>30€</em></span>
            <span>Une heure et demie: <em>40€</em></span>
          </div>
        </div>
      </v-col>
      <!-- <v-col id="text-infos" align-self="center">
        <div id="tarifs">
          <h4>Cours en petit groupe</h4>
          <div id="tarifs-text">
            <span>Une heure et demie: <em>15€</em> par personne</span>
            <span>De 3 à 5 personnes</span>
            <span>Engagement sur 10 cours</span>
          </div>
        </div>
      </v-col> -->
    </v-row>
    <!-- <div class="d-flex justify-center">
      <p class="center" align="center">
        ♣ Si vous souhaitez intégrer un cours en duo ou en petit groupe,
        contactez moi et nous verrons si je peux vous grouper avec des gens du
        même niveau. ♣
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "SecondBanner",
  props: {},
};
</script>

<style>
#infos-banner {
  padding-block: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  margin-inline: 20px;
}
#text-infos {
  text-align: center;
}
p {
  padding-block: 10px;
}
#infos-title {
  margin-top: 65px;
  padding-block: 20px 10px;
  text-align: center;
}
#tarifs {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  min-height: 200px;
  row-gap: 10px;
  padding-top: 10px;
  background: #f7faff;
}
#tarifs-text {
  display: flex;
  flex-direction: column;
}
h4 {
  padding: 10px;
}
span {
  padding-block: 5px;
  flex-grow: 1;
}
span em {
  color: #9d71e8;
  font-size: larger;
}
#image-infos {
  max-width: 1080px;
  align-self: center;
  justify-content: center;
}
#img {
  display: block;
  max-width: 800px;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  height: auto;
  margin: 20px;
}
.emphasize {
  font-style: italic;
  padding-block: 20px 20px;
  font-size: x-large;
}
.center {
  justify-content: center;
  justify-items: center;
  justify-self: center;
  display: flex;
  text-align: center;
}
.info-title {
  margin-top: 70px;
}
</style>
